import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useAuth } from '@hummingbirdtechgroup/wings-auth';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { User } from '@hummingbirdtechgroup/wings-auth/dist/types';

function useLocation() {
  return window.location.pathname + window.location.search;
}

const getRole = (user: User) => (user.staff ? 'STAFF' : user.clients?.[0].role);

function GoogleAnalytics(): null {
  const { user } = useAuth();
  const url = useLocation();
  const { freshAnalyticsCode } = useConfig();

  useEffect(() => {
    if (user) {
      ReactGA.initialize(freshAnalyticsCode, {
        // set this to true when debuggin in the console.
        debug: false,
        gaOptions: {
          userId: user.uid,
        },
      });
      ReactGA.set({
        dimension1: user.uid,
        dimension2: getRole(user),
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      ReactGA.set({
        dimension1: user.uid,
        dimension2: getRole(user),
      });
      ReactGA.pageview(url);
    }
  }, [url, user]);

  return null;
}

export default GoogleAnalytics;
